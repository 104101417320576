<template>
  <div id="heatMapWrapper">
    <v-app class="desktopLayoutClass" >
      <div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
              {{$t("Warning_Message.Unauthorized")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogUnauthorized"
                >
                {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
              {{$t("Warning_Message.Something_Went_Wrong")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogError" style="background-color: #1467BF;">
                {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
          <v-row justify="center">
            <v-dialog v-model="restart_app" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break: normal">
                  {{ $t("Warning_Message.Error_Saving_Data") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="closeRestartApp">
                    {{ $t("Warning_Message.Ok") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="allow_user_data" max-width="290">
            <v-card>
              <v-card-title style="word-break: normal; font-size:15px;">
              {{$t("Warning_Message.Allow_User_Data")}}
              </v-card-title>
              <v-card-actions style="display: flex; justify-content: center;">
                <v-btn class="primary" text @click="SaveKBZPayInformation" style="padding: 0px 30px; ">
                {{$t("Warning_Message.Allow")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="TestPopUp" persistent max-width="310">
            <v-card>
              <v-card-title style="word-break: normal">
              {{$t("Customer.UserProfile.Popup_Message")}}
              </v-card-title>
              <div class="mainBtnClass">
                <div>
                  <v-btn  class="btnClass" outlined color="white" width="110px" @click="TestPopUp=false">
                  {{ $t("Customer.Home.Cancel") }}
                  </v-btn>
                </div>
                <div>
                  <v-btn color="primary" width="110px" @click="redirectToVideoCallTestPage" style="background-color: #1467BF;">
                    {{ $t("Customer.Home.Verify_Now") }}
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="editBrowserGuestPopup" max-width="290">
            <v-card>
              <v-card-title style="word-break: normal; font-size:15px;">
              {{$t("Warning_Message.Edit_Guest_From_Browser_Popup")}}
              </v-card-title>
              <v-card-actions style="display: flex; justify-content: center;">
                <v-btn class="primary" text @click="closeEditBrowserGuestPopup()" style="padding: 0px 30px; ">
                {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
  
      <v-container fluid class="Maindivprofile">
        <v-card style="border: 1px solid #E0E0E0; border-radius: 8px;" elevation="0">
        <!-- commented for hide verify devices  -->
        <!-- <div v-if="verifiedStatus == 'true'" style="background:#6FCF97; width:40%; border-radius: 6px 0px; ">
        <p style="color:white; padding:5px; font-weight:600 ">   <img style="margin-right:4px; width:15px"
                            src="https://s3iconimages.mymedicine.com.mm/tickMark.svg"
                          /> {{ $t("Customer.UserProfile.Verified") }}</p>
        </div> -->
          <div style="margin: 15px">
            <v-row no-gutters>
              <v-col cols="12" sm="12">
                <v-row no-gutters>
                  <v-col cols="2" sm="2">
                    <img src="https://img.icons8.com/color/50/000000/user.png" />
                  </v-col>
                  <v-col cols="3" sm="3">
                    <div
                      style="
                        display: table;
                        height: 100%;
                        font-weight: 700;
                        font-size: 16px;
                        text-align: left;
                      "
                    >
                      <div style="display: table-cell; vertical-align: middle">
                        {{ customer_name }}
                        <!-- Name -->
                        <div
                          style="
                            font-weight: 400;
                            font-size: 12px;
                            text-align: left;
                          "
                        >
                          {{ mobileNumber }}
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col v-if="$i18n.locale == 'en'" cols="3" sm="3"> </v-col>
                  <v-col v-if="$i18n.locale == 'en'" cols="3" sm="3">
                    <div style="display: table; height: 100%">
                      <div style="display: table-cell; vertical-align: middle">
                        <v-btn :disabled="!customerData" @click="editCustomerProfile" elevation="0" color="primary">
                          <span style="margin-right: 8px; font-weight: 600">{{
                            $t("Customer.UserProfile.Edit")
                          }}</span>

                          <img
                            src="https://s3iconimages.mymedicine.com.mm/user_profile_edit.svg"
                          />
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col v-if="$i18n.locale == 'mm'" cols="2" sm="2"> </v-col>
                  <v-col v-if="$i18n.locale == 'mm'" cols="3" sm="3">
                    <div style="display: table; height: 100%">
                      <div style="display: table-cell; vertical-align: middle">
                        <v-btn :disabled="!customerData" @click="editCustomerProfile" color="primary">
                          <span style="margin-right: 8px; font-weight: 600">{{
                            $t("Customer.UserProfile.Edit")
                          }}</span>
                          <img
                            src="https://s3iconimages.mymedicine.com.mm/user_profile_edit.svg"
                          />
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <br>
        <!-- commented for hide verify devices  -->
        <!-- <div  v-if="verifiedStatus == 'false'">
          <v-card
              style="
                background:#F2994A
                box-shadow: 0px 8px 20px 0px #0547891a;
                border-radius: 8px;
                box-shadow: 0px 8px 20px rgba(5, 71, 137, 0.1);
              "
              >
            <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0px 10px;
                  margin: 10px;
                "
              >
              <div>
                <span style="display:flex; font-size:16px; padding: 20px 5px;">{{ $t("Customer.UserProfile.Verify_Message") }}</span>
              </div>
              <div>
                <v-btn :disabled = "customerData == {}" @click="TestPopUp=true">
                  {{ $t("Customer.UserProfile.Verify_Now") }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </div> -->
        <br>
        <!-- <div v-scroll.self="onScroll" class="overflow-y-auto" max-height="400"> -->
        <div style="padding-bottom: 30px;">
          <div style="display: flex; flex-direction: column" align="left">
            <v-card style="border: 1px solid #E0E0E0; border-radius: 8px;" elevation="0">
              <div style="margin: 0px 15px">
                <div>
                  <!-- Delete this div element(only element, not inner content when telemedicine releases) -->
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      padding: 16px 10px;
                    "
                    @click="redirectToMyOrders"
                  >
                    <div style="display: flex; flex-direction: row">
                      <div style="padding-right: 8px">
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/user_orders.svg"
                        />
                      </div>
                      <div>
                        <span class="css-title">{{
                          $t("Customer.UserProfile.My_Orders")
                        }}</span>
                      </div>
                    </div>
                    <div>
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                      />
                    </div>
                  </div>
                </div>
                <v-divider/>
                <div>
                  <!-- Delete this div element(only element, not inner content when telemedicine releases) -->
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      padding: 16px 10px;
                    "
                    @click="redirectToMyBookings"
                  >
                    <div style="display: flex; flex-direction: row">
                      <div style="padding-right: 8px">
                        <img style="width:24px; height:24px;"
                          src="https://s3iconimages.mymedicine.com.mm/myAppointmentIcon.svg"
                        />
                      </div>
                      <div>
                        <span class="css-title">{{
                          $t("Customer.UserProfile.My_Appointments")
                        }}</span>
                      </div>
                    </div>
                    <div>
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                      />
                    </div>
                  </div>
                </div>
                <v-divider/>
                <div>
                  <!-- Delete this div element(only element, not inner content when telemedicine releases) -->
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      padding: 16px 10px;
                    "
                    @click="redirectToOPDBookings"
                  >
                    <div style="display: flex; flex-direction: row">
                      <div style="padding-right: 8px">
                        <img style="width:24px; height:24px;"
                          src="https://s3iconimages.mymedicine.com.mm/myAppointmentIcon.svg"
                        />
                      </div>
                      <div>
                        <span class="css-title">{{$t("Customer.OpdAppointments.OPD_Appointments")}}  </span>
                      </div>
                    </div>
                    <div>
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                      />
                    </div>
                  </div>
                </div>
                <v-divider/>
                <div>
                  <!-- Delete this div element(only element, not inner content when telemedicine releases) -->
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      padding: 16px 10px;
                    "
                    @click="redirectToPackageBookings"
                  >
                    <div style="display: flex; flex-direction: row">
                      <div style="padding-right: 8px">
                        <img style="width:24px; height:24px;"
                          src="https://s3iconimages.mymedicine.com.mm/myAppointmentIcon.svg"
                        />
                      </div>
                      <div>
                        <span class="css-title">Package Appointments  </span>
                      </div>
                    </div>
                    <div>
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                      />
                    </div>
                  </div>
                </div>
                <v-divider/>
                <!-- <div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      padding: 16px 10px;
                    "
                    @click="redirectToNurseBooking"
                  >
                    <div style="display: flex; flex-direction: row">
                      <div style="padding-right: 8px">
                        <img style="width:24px; height:24px;"
                          src="https://s3iconimages.mymedicine.com.mm/nurseBookingIcon.svg"
                        />
                      </div>
                      <div>
                        <span class="css-title">{{
                          'Nurse Bookings'
                        }}</span>
                      </div>
                    </div>
                    <div>
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                      />
                    </div>
                  </div>
                </div>
                <v-divider/> -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 16px 10px;
                  "
                  @click="redirectToMyFamily"
                  >
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-right: 8px">
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/user_relations.svg"
                      />
                    </div>
                    <div>
                      <span class="css-title">{{
                        $t("Customer.UserProfile.My_Dependents")
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                    />
                  </div>
                </div>
                <v-divider />
                <!-- TESTING FOR EPHARMACY ADDRESSBOOK -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 16px 10px;
                  "
                  @click="redirectToAddressbook"
                  >
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-right: 8px">
                      <img style="width: 24px; height: 24px;"
                        src="https://s3iconimages.mymedicine.com.mm/AddressBookIconFull.svg"
                      />
                    </div>
                    <div>
                      <span class="css-title">{{
                        $t("Customer.UserProfile.Address_Book")
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                    />
                  </div>
                </div>
                <v-divider />
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 16px 10px;
                  "
                  @click="redirectToMyLinkedContacts"
                  v-if="isKbzEmployee"
                    >
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-right: 8px">
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/user_relations.svg"
                      />
                    </div>
                    <div>
                      <span class="css-title">{{
                        $t("Customer.UserProfile.My_Linked_Contacts")
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                    />
                  </div>
                </div>
                <v-divider v-if="isKbzEmployee"/>
                <div
                  v-if="customer_name != 'Guest'"
                  style="
                    display: flex; flex-direction: row;
                    justify-content: space-between;
                    padding: 16px 10px;
                  "
                  @click="redirectToMySubscription"
                    >
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-right: 8px">
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/mySubscriptionIcon.svg"
                      />
                    </div>
                    <div>
                      <span class="css-title">{{ $t("Customer.UserProfile.My_Subscription")
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                    />
                  </div>
                </div>
                <v-divider />
                <div style="display: none">
                  <!-- Delete this div element(only element, not inner content when telemedicine releases) -->
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      padding: 16px 10px;
                    "
                      >
                    <div style="display: flex; flex-direction: row">
                      <div style="padding-right: 8px">
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/user_address.svg"
                        />
                      </div>
                      <div>
                        <span class="css-title">{{
                          $t("Customer.UserProfile.My_Address")
                        }}</span>
                      </div>
                    </div>
                    <div>
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                      />
                    </div>
                  </div>
                  <v-divider />
                </div>

                <!-- <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 16px 10px;
                  "
                  @click="redirectToVideocallServicePage"

                  >
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-right: 8px">
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/user_relations.svg"
                      />
                    </div>
                    <div>
                      <span class="css-title"> Video call Service Page</span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                    />
                  </div>
                </div>
                <v-divider /> -->

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 16px 10px;
                  "
                  @click="redirectToChangePassword"
                  v-show="false"
                    >
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-right: 8px">
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/user_change_password.svg"
                      />
                    </div>
                    <div>
                      <span class="css-title">{{
                        $t("Customer.UserProfile.Change_Password")
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                    />
                  </div>
                </div>
                <v-divider v-show="false"></v-divider>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 16px 10px;
                  "
                  @click="redirectToLanguageChange"
                    >
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-right: 8px">
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/user_language.svg"
                      />
                    </div>
                    <div>
                      <span class="css-title">{{
                        $t("Customer.UserProfile.Language_Settings")
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                    />
                  </div>
                </div>
                <v-divider />
                <!-- commented for hide verify devices  -->
                <!-- <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 16px 10px;
                  "
                  @click="redirectToListOfDevices"
                >
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-right: 8px">
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/user_language.svg"
                      />
                    </div>
                    <div>
                      <span class="css-title">{{$t("Customer.UserProfile.List_Of_Devices") }}</span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                    />
                  </div>
                </div>
                <v-divider /> -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 16px 10px;
                  "
                  @click="redirectToFAQ"
                >
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-right: 8px">
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/user_faq.svg"
                      />
                    </div>
                    <div>
                      <span class="css-title">{{
                        $t("Customer.UserProfile.FAQ")
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                    />
                  </div>
                </div>
                <v-divider />
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 16px 10px;
                  "
                  @click="gotoiframePage"
                >
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-right: 8px">
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/user_faq.svg"
                      />
                    </div>
                    <div>
                      <span class="css-title">Health Articles</span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                    />
                  </div>
                </div>
                <v-divider />
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 16px 10px;
                  "
                  @click="redirectToTC"
                >
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-right: 8px">
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/user_tc.svg"
                      />
                    </div>
                    <div>
                      <span class="css-title">{{
                        $t("Customer.UserProfile.Terms_And_Conditions")
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
                    />
                </div>
              </div>
            </div>
          </v-card>
          <br />
        </div>
        <div v-if="isKbzApp">
        <v-card style="border: 1px solid #E0E0E0; border-radius: 8px;" elevation="0" @click="redirectToLogin" v-if="!isLogingOut">
          <div
            v-if="platform!='AYA'"
            style="
              display: flex;
              flex-direction: row;
              padding: 0px 10px;
              margin: 16px;
              "
            >
              <div style="padding-right: 8px">
                <img src="https://s3iconimages.mymedicine.com.mm/user_logout.svg" />
              </div>
              <div>
                <span class="css-title">{{
                  $t("Customer.UserProfile.Logout")
                }}</span>
              </div>
            </div>
          </v-card>
          <div v-else>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
            <p>{{ $t("Customer.UserProfile.Logging_Out") }} </p>
          </div>
        </div>
        </div>
      </v-container>
      <!-- <bottomnavbar style="position:sticky; bottom:0px; width: 100%;" :pageName=pageName :isLoggedIn=currentCustomer :kbzEmployee=isKbzEmployee></bottomnavbar> -->
      <div class="mb-2 py-2 bottom-navbar-segment mobile-bottom-nav-bar">
        <bottom-nav-bar-component  :selectedOption="3"/>
        <!-- <bottomnavbar highlight_icon="profile" class="teleconsultationBottomBar"></bottomnavbar> -->
      </div>
    </v-app>
  </div>
</template>
<script>
import HorizontalScroll from "vue-horizontal-scroll";
import banner from "./banner.vue";
// import bottomnavbar from "./bottomnavbar.vue";
import bottomnavbar from "../../../components/navigationComponents/bottomNavigationBar.vue";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import moment from "moment";
import * as d3 from 'd3';
import * as h337 from 'heatmap.js';
import BottomNavBarComponent from '../../../components/nurseBookingPageComponents/bottomNavBarComponent.vue';
export default {
  name: "CustomerHome",
  components: {
    banner,
    bottomnavbar,
    HorizontalScroll,
    NavBar,
    BottomNavBarComponent,
  },
  data() {
    return {
      hi: "",
      appointment_list: [],
      mobileNumber: "",
      pageName: "UserProfile",
      genderList: ["Male", "Female", "Other"],
      editProfile: false,
      currentCustomer: "",
      customer_id: "",
      customer_name: "",
      editHeight: "",
      editWeight: "",
      auth_error_enable: false,
      error_enable: false,
      editEmail: "",
      editDob: "",
      editGender: "",
      editProfileSuccessModal: false,
      customerData: null,
      isMobile: false,
      isKbzEmployee: false,
      isLogingOut: false,
      userConfigDetails:'',
      verifiedStatus:'',
      TestPopUp: false,
      isKbzApp:true,
      isKBZPayUser: false,
      allow_user_data: false,
      restart_app: false,
      editBrowserGuestPopup: false,
      userType: '',
      platform: '',
    };
  },
  mounted() {
    this.platform = localStorage.getItem('platform');
    this.$i18n.locale = this.$store.state.locale;
     var userAgent = navigator.userAgent
     //var userAgent = "Mozilla/5.0 (Linux; Android 9; MI 6X Build/PKQ1.180904.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/76.0.3809.111 Mobile Safari/537.36; kbzpay app/customer platform/android lang/my unicode/true version/5.0.3"
    if(userAgent.includes("kbzpay")){
      this.isKbzApp = false;
    }
    if (window.xm){
      document.title = '';
    } else {
    if(this.$store.state.locale == 'en')
      document.title = "User Profile"
    else
      document.title = 'အသုံးပြုသူ၏ပရိုဖိုင်'
    }
    this.verifiedStatus = this.$cookies.get("verifiedStatus")
    this.customer_name = this.$store.state.name;
    this.isKbzEmployee = this.$store.state.employee;
    this.isMobile = this.$device.mobile;
    this.currentCustomer = this.$cookies.get("customerToken");
    if (!this.currentCustomer) {
      this.$router.push({
        name: "Login",
      });
    } else {
      //logging page hit - start
      let x =  localStorage.getItem("currentURL");
      let pageName = "UserProfileScreen";
      if( x == pageName)
        x = "";
      localStorage.setItem("currentURL", pageName);
      var pageAccessBody = {
        token: this.currentCustomer,
        pageName: pageName,
        previousPage: x ? x : "START"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
      .then((logSuccess) =>{

      })
      .catch((logError) =>{

      });
      //logging page hit - end

      var myProfilePageData = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER",
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/myProfilePage",
          myProfilePageData
        )
        .then((myProfilePageDataResponse) => {
          this.customerData = myProfilePageDataResponse.data.data;
          this.customer_name = myProfilePageDataResponse.data.data.customer_name;
          this.mobileNumber = this.customer_name == "Guest" ?  "" : myProfilePageDataResponse.data.data.phoneNumber;
          this.editEmail = myProfilePageDataResponse.data.data.email;
          this.isKbzEmployee = myProfilePageDataResponse.data.data.kbzEmployee;
          this.userType = myProfilePageDataResponse.data.data.user_type;
          this.isKBZPayUser = this.userType === 'APP_USER' || this.userType === 'GUEST_USER';
          // this.verifiedStatusFun(this.customer_id);
          this.userPageViewTable();
          if (localStorage.getItem('showHeatMap')) {
            this.drawHeatMap();
          }
        })
        .catch((myProfilePageDataError) => {
          if (myProfilePageDataError.response.status == 401) {
            this.auth_error_enable = true;
          } else {
            this.error_enable = true;
          }
        });
    }
  },

  methods: {
    gotoiframePage() {
      this.$router.push({
          name: "HealthArticles",
      });
    },
    drawHeatMap(){
      let width = window.innerWidth;
      let height = window.innerHeight
      var holder = d3.select('#heatMapWrapper').append('svg')
      var getAllLogsFromDBBody = {
        token: this.currentCustomer,
        pageName: 'UserProfile'
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getAllLogsFromDB", getAllLogsFromDBBody)
      .then((getAllLogsFromDBResponse)=>{
        if (getAllLogsFromDBResponse.data.data.length !== 0){
          let dataPoint = getAllLogsFromDBResponse.data.data
          let maxValue =  Math.max.apply(Math, dataPoint.map(function(o) { return o.value; }))
          let minValue =  Math.min.apply(Math, dataPoint.map(function(o) { return o.value; }))
          var heatMapConfig = h337.create({
            container: document.getElementById('heatMapWrapper'),
            radius: 33,
            blur: .95
          })
          let sampleData = {
            max: maxValue,
            min: minValue,
            data: dataPoint
          }
          console.log('sampleData', sampleData)
          heatMapConfig.setData(sampleData)
        }
      })
      .catch((error)=>{
        console.log("Error")
      })
    },
    onClick(e){
        var createClickLogBody = {
          token: this.currentCustomer,
          typeOfUser: 'CUSTOMER',
          clientX: String(e.clientX),
          clientY: String(e.clientY),
          pageName: 'UserProfile'
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/createClickLog",createClickLogBody)
        .then((createClickLogResponse)=>{
          // console.log('createClickLogResponse', createClickLogResponse)
        })
        .catch((createClickLogError) => {

        });
    },
    closeRestartApp()
    {
      this.restart_app = false;
      kbzpay.gotoFunction('native://kbz/customer/homepage/wallet');
    },
    SaveKBZPayInformation() {
      this.allow_user_data = false;
      // Get kbzpay token.
      let thisobj = this;
      window.xm.native('getKBZPayToken', { appid: process.env.VUE_APP_MERCH_ID }).then(function(result) {
        thisobj.urlparams = result;
        let allowKBZPayDataBody = {
          token: thisobj.currentCustomer,
          typeOfUser: "CUSTOMER",
          // kbzpayToken: 'fa481fb7e159799c9c0888afedb8ee0d'
          kbzpayToken: thisobj.urlparams.token
        };
        axios.post(process.env.VUE_APP_BACKEND_URL + "/postConsentAcceptance" , allowKBZPayDataBody)
        .then(() => {
          thisobj.$router.go();
        }).catch((error) => {
          console.log("Error: ", error);
          if(userAgent.includes("kbzpay") || userAgent.includes("hwminiapp")) {
            thisobj.restart_app = true;
          } else if(error.response.status == 401) {
            thisobj.auth_error_enable = true;
          } else {
            thisobj.error_enable = true;
          }
        });
      });
    },
    onScroll() {
      console.log("scroll called");
    },
    closeDialogUnauthorized() {
      this.auth_error_enable = false;
      this.$router.push({
        name: "Login",
      });
    },
    closeDialogError() {
      this.error_enable = false;
    },
    redirectToMyBookings() {
      this.$router.push({
        name: "myBookings",
      });
    },
    redirectToOPDBookings() {
      this.$router.push({
        name: "listOPDAppointmentsPage",
      });
    },
    
    redirectToPackageBookings() {
      this.$router.push({
        name: "ListPackageAppointments",
      });
    },
    redirectToNurseBooking(){
      this.$router.push('/appointments');
    },
    redirectToMyOrders() {
      this.$router.push({
        name: "MyOrdersPage",
      });
    },
    redirectToLogin() {
      localStorage.removeItem("currentURL");
      this.isLogingOut = true;
      // this.$cookies.set("alerted", false, "1h")
      this.$cookies.remove("alerted");
      var signOutData = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER"
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/customerSignOut", signOutData)
        .then((customerSignOutResponse) => {
          this.$cookies.remove("customerToken");
          this.$store.dispatch("changeName", "");
          this.$store.dispatch("cleanVuex");
          this.isLogingOut = false;
          this.$router.push({
            name: "Login",
          });
        })
        .catch((customerSignOutError) => {
          this.isLogingOut = false;
        });
    },
    verifiedStatusFun(userId) {
        var userAgent = navigator.userAgent;
        var readParticularUserConfigData = {
          token: this.currentCustomer,
          userId: userId,
          // userAgent:userAgent,
          // userAgent:"Mozilla/5.0 (iPhone; CPU iPhone OS 6_0 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10A5376e Safari/8536.25 (compatible; Googlebot-Mobile/2.1; +http://www.google.com/bot.html)",
          typeOfUser: "CUSTOMER",
        };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/readParicularUserConfig", readParticularUserConfigData)
      .then((readParicularUserConfigResponse) => {
        this.userConfigDetails = readParicularUserConfigResponse.data.user_config_data
        this.verifiedStatus = this.userConfigDetails.verified_status
      }).catch(() => {
        this.isLogingOut = false;
      });
    },
    redirectToMyFamily() {
      this.$router.push({
        name: "MyFamily",
      });
    },
    redirectToAddressbook() {
      this.$router.push({
        name: "MyAddressbook",
      });
    },
     redirectToVideocallServicePage() {
      this.$router.push({
        name: "videoCallServiceTestPage",
      });
    },
    redirectToMyLinkedContacts() {
      this.$router.push({
        name: "MyLinkedContacts",
      });
    },
    redirectToMySubscription(){
      this.$router.push({
        name: "Packages",
      });
    },
    redirectToChangePassword() {
      // this.$router.push({
      //   name: "ChangePassword",
      // });
    },
    redirectToFAQ() {
      this.$router.push({
        name: "FAQ",
      });
    },
    redirectToTC() {
      this.$router.push({
        name: "TermsAndConditions",
      });
    },
    redirectToLanguageChange() {
      this.$router.push({
        path: "/customer/language",
      });
    },
    redirectToVideoCallTestPage(){
        this.$router.push({
        name: "VideoCallTestPage",
      });
    },
    redirectToListOfDevices() {
      this.$router.push({
        // path: "/customer/listOfDevices"

        name:"ListOfDevices",
        // params: {
        //   'userId': this.customer_id,
        // }
      });
    },
    editCustomerProfile() {
      if (this.customer_name != "Guest") {
        this.$router.push({
          name: "EditUserProfile",
        });
      } else {
        let userAgent = navigator.userAgent;
        if (userAgent.includes('hwminiapp') && this.userType != 'BROWSER_USER') {
          this.allow_user_data = true;
        } else {
          this.editBrowserGuestPopup = true;
        }
      }
    },
    closeEditBrowserGuestPopup() {
      this.editBrowserGuestPopup = false;
    },
    closeCustomerProfile() {
      this.editProfile = false;
    },
    closeEditProfileSuccessModal() {
      this.editProfileSuccessModal = false;
      this.editProfile = false;
    },

    updateCustomerInfo() {
      let modifyCustomerData = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER",
        height: this.editHeight,
        weight: this.editWeight,
        email: this.editEmail,
        dob: this.editDob,
        gender: this.editGender,
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/modifyCustomer", modifyCustomerData).then((modifyCustomerSuccess) => {
          this.editProfileSuccessModal = true;
      }).catch((modifyCustomerError) => {

      });
    },
    userPageViewTable() {
      if (this.currentCustomer === null) {
          var userPageViewTableBody = {
            pageName : 'userProfile',
            typeOfUser: 'CUSTOMER'
          };
      } else {
          var userPageViewTableBody = {
            pageName: 'userProfile',
            typeOfUser: 'CUSTOMER',
            token: this.currentCustomer
          };
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/userPageViewTable", userPageViewTableBody)
      .catch((userPageViewTableError) => {
          console.log('Error in adding userPageViewTable', userPageViewTableError);
      });
    },
  },
};
</script>
<style scoped>
/* @media (min-width: 800px) {
  .Maindivprofile {
    width: 800px !important;
    margin-left: auto !important ;
    margin-right: auto !important ;
  }
} */
.teleconsultationBottomBar {
    @media (min-width: 425px) {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 425px;
    }
}
.v-bottom-navigation--fixed {
    left: auto !important
}
.mobile-bottom-nav-bar {
    @media (max-width: 600px) {
        
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        display: block !important;

    }

    @media (min-width: 1265px) {
        display: block !important;
    }
}
.v-sheet.v-card{
 box-shadow: 0px 8px 20px rgba(5, 71, 137, 0.1);
}
.v-btn{
  text-transform: none !important;
}
.mainBtnClass{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
}
.btnClass{
  background-color: grey;
}
</style>